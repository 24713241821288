import styled from 'styled-components';

export const MainContainer = styled.div`
  background: #fff;
  color: #661988;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const InnerContainer = styled.div`
  padding: 40px 10px 10px 10px;
  width: 740px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
`;

export const HeaderH2 = styled.h2`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Figtree-Light', arial, sans-serif;
  font-weight: normal !important;
  color: #733695;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const ParaText = styled.p`
  margin: 0 0 20px 0;
  padding: 0;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #661988;
  b {
    font-family: 'Figtree-Bold', arial, sans-serif;
  }
`;

export const LinkStyle = styled.a`
  text-decoration: none;
  color: #c77dec;
  :hover {
    text-decoration: none;
    color: #661988;
  }
`;
